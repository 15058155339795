import React, { FC, memo } from 'react';

import usePrefix from 'utils/usePrefix';
import { userRoles } from 'utils/userRoles';

import { useApp } from 'App';
import {
  ADVICES_URL,
  ASSIGNMENTS_URL,
  CHAT_URL, INSTRUCTIONS_URL,
  NOTICES_URL,
  PROFILE_URL,
  REPORTS_URL,
  SCHOOLS_URL,
  USERS_URL,
} from 'App/constants';

import NavigationItem from './NavigationItem';
import { NavigationItemStyled, NavigationMenuStyled, SeparatorStyled } from './styles';

const MainMenu: FC = () => {
  const [{ profile }] = useApp();
  const t = usePrefix('Navigation');

  const isAdmin = profile?.role === userRoles.admin;
  const isDirector = profile?.role === userRoles.director;
  const showUsersPermissionsList = profile?.show_user_permissions_list;

  return (
    <NavigationMenuStyled>
      {(isAdmin || isDirector) && (
        <NavigationItem icon="chartDonut" to={REPORTS_URL} label={t('reports')} />
      )}
      {isAdmin ? (
        <NavigationItem icon="lightbulb" to={NOTICES_URL} label={t('notices')} />
      ) : (
        <>
          <NavigationItem icon="chatTeardrop" to={CHAT_URL} label={t('chat')} />
          <NavigationItem icon="lightbulb" to={ADVICES_URL} label={t('advices')} />
          <NavigationItem icon="notebook" to={INSTRUCTIONS_URL} label={t('materials')} />
        </>
      )}
      {isAdmin && (
        <>
          <NavigationItem icon="users" to={USERS_URL} label={t('users')} />
          <NavigationItem icon="buildingOffice" to={SCHOOLS_URL} label={t('schools')} />
        </>
      )}
      {isDirector && showUsersPermissionsList && (
        <NavigationItem icon="pencil" to={ASSIGNMENTS_URL} label={t('assignments')} />
      )}
      <NavigationItemStyled>
        <SeparatorStyled />
      </NavigationItemStyled>
      <NavigationItem icon="user" to={PROFILE_URL} label={t('profile')} />
    </NavigationMenuStyled>
  );
};

export default memo(MainMenu);
