import React, { FC } from 'react';

import { ICON_BASIC_COLOR } from './constants';

export interface IconProps {
  width: string;
  height: string;
}

export const ArticleIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path
      d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H40V56H216V200ZM184,96a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,96Zm0,32a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,128Zm0,32a8,8,0,0,1-8,8H80a8,8,0,0,1,0-16h96A8,8,0,0,1,184,160Z"></path>
  </svg>
);

export const BackspaceIcon: FC<IconProps> = ({width, height, ...props}) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path
      d="M216,40H68.53a16.08,16.08,0,0,0-13.72,7.77L9.14,123.88a8,8,0,0,0,0,8.24l45.67,76.11A16.08,16.08,0,0,0,68.53,216H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM61.67,204.12,68.53,200h0ZM216,200H68.53l-43.2-72,43.2-72H216ZM106.34,146.34,124.69,128l-18.35-18.34a8,8,0,0,1,11.32-11.32L136,116.69l18.34-18.35a8,8,0,0,1,11.32,11.32L147.31,128l18.35,18.34a8,8,0,0,1-11.32,11.32L136,139.31l-18.34,18.35a8,8,0,0,1-11.32-11.32Z"></path>
  </svg>
);

export const BuildingOfficeIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M248,208H232V96a8,8,0,0,0,0-16H184V48a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16V208H24a8,8,0,0,0,0,16H248a8,8,0,0,0,0-16ZM216,96V208H184V96ZM56,48H168V208H144V160a8,8,0,0,0-8-8H88a8,8,0,0,0-8,8v48H56Zm72,160H96V168h32ZM72,80a8,8,0,0,1,8-8H96a8,8,0,0,1,0,16H80A8,8,0,0,1,72,80Zm48,0a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H128A8,8,0,0,1,120,80ZM72,120a8,8,0,0,1,8-8H96a8,8,0,0,1,0,16H80A8,8,0,0,1,72,120Zm48,0a8,8,0,0,1,8-8h16a8,8,0,0,1,0,16H128A8,8,0,0,1,120,120Z"></path>
  </svg>
);

export const CaretDownIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
  </svg>
);

export const CaretLeftIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path>
  </svg>
);

export const CaretRightIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
  </svg>
);

export const CaretUpIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M213.66,165.66a8,8,0,0,1-11.32,0L128,91.31,53.66,165.66a8,8,0,0,1-11.32-11.32l80-80a8,8,0,0,1,11.32,0l80,80A8,8,0,0,1,213.66,165.66Z"></path>
  </svg>
);

export const ChartDonutIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M128,24a8,8,0,0,0-8,8V88a8,8,0,0,0,8,8,32,32,0,1,1-27.72,16,8,8,0,0,0-2.93-10.93l-48.5-28A8,8,0,0,0,37.92,76,104,104,0,1,0,128,24ZM48.09,91.1,83,111.26A48.09,48.09,0,0,0,80,128c0,1.53.08,3,.22,4.52L41.28,143A88.16,88.16,0,0,1,48.09,91.1Zm-2.67,67.31,39-10.44A48.1,48.1,0,0,0,120,175.32v40.31A88.2,88.2,0,0,1,45.42,158.41ZM136,215.63V175.32a48,48,0,0,0,0-94.65V40.36a88,88,0,0,1,0,175.27Z"></path>
  </svg>
);

export const ChartLineIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M232,208a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V48a8,8,0,0,1,16,0v94.37L90.73,98a8,8,0,0,1,10.07-.38l58.81,44.11L218.73,90a8,8,0,1,1,10.54,12l-64,56a8,8,0,0,1-10.07.38L96.39,114.29,40,163.63V200H224A8,8,0,0,1,232,208Z"></path>
  </svg>
);

export const ChatTeardropIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M132,24A100.11,100.11,0,0,0,32,124v84a16,16,0,0,0,16,16h84a100,100,0,0,0,0-200Zm0,184H48V124a84,84,0,1,1,84,84Zm12-80a12,12,0,1,1-12-12A12,12,0,0,1,144,128Zm-44,0a12,12,0,1,1-12-12A12,12,0,0,1,100,128Zm88,0a12,12,0,1,1-12-12A12,12,0,0,1,188,128Z"></path>
  </svg>
);

export const CheckIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M229.66,77.66l-128,128a8,8,0,0,1-11.32,0l-56-56a8,8,0,0,1,11.32-11.32L96,188.69,218.34,66.34a8,8,0,0,1,11.32,11.32Z"></path>
  </svg>
);

export const CheckCircleIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
  </svg>
);

export const CheckCircleFillIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z"></path>
  </svg>
);

export const ClockCountdownIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M232,136.66A104.12,104.12,0,1,1,119.34,24,8,8,0,0,1,120.66,40,88.12,88.12,0,1,0,216,135.34,8,8,0,0,1,232,136.66ZM120,72v56a8,8,0,0,0,8,8h56a8,8,0,0,0,0-16H136V72a8,8,0,0,0-16,0Zm40-24a12,12,0,1,0-12-12A12,12,0,0,0,160,48Zm36,24a12,12,0,1,0-12-12A12,12,0,0,0,196,72Zm24,36a12,12,0,1,0-12-12A12,12,0,0,0,220,108Z"></path>
  </svg>
);

export const ClockCounterClockwiseIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M136,80v43.47l36.12,21.67a8,8,0,0,1-8.24,13.72l-40-24A8,8,0,0,1,120,128V80a8,8,0,0,1,16,0Zm-8-48A95.44,95.44,0,0,0,60.08,60.15C52.81,67.51,46.35,74.59,40,82V64a8,8,0,0,0-16,0v40a8,8,0,0,0,8,8H72a8,8,0,0,0,0-16H49c7.15-8.42,14.27-16.35,22.39-24.57a80,80,0,1,1,1.66,114.75,8,8,0,1,0-11,11.64A96,96,0,1,0,128,32Z"></path>
  </svg>
);

export const CloseIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
  </svg>
);

export const CloseCircleIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
  </svg>
);

export const CloseCircleFillIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm37.66,130.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
  </svg>
);

export const DownloadIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M224,144v64a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V144a8,8,0,0,1,16,0v56H208V144a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,124.69V32a8,8,0,0,0-16,0v92.69L93.66,98.34a8,8,0,0,0-11.32,11.32Z"></path>
  </svg>
);

export const GlobeIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm88,104a87.62,87.62,0,0,1-6.4,32.94l-44.7-27.49a15.92,15.92,0,0,0-6.24-2.23l-22.82-3.08a16.11,16.11,0,0,0-16,7.86h-8.72l-3.8-7.86a15.91,15.91,0,0,0-11-8.67l-8-1.73L96.14,104h16.71a16.06,16.06,0,0,0,7.73-2l12.25-6.76a16.62,16.62,0,0,0,3-2.14l26.91-24.34A15.93,15.93,0,0,0,166,49.1l-.36-.65A88.11,88.11,0,0,1,216,128ZM143.31,41.34,152,56.9,125.09,81.24,112.85,88H96.14a16,16,0,0,0-13.88,8l-8.73,15.23L63.38,84.19,74.32,58.32a87.87,87.87,0,0,1,69-17ZM40,128a87.53,87.53,0,0,1,8.54-37.8l11.34,30.27a16,16,0,0,0,11.62,10l21.43,4.61L96.74,143a16.09,16.09,0,0,0,14.4,9h1.48l-7.23,16.23a16,16,0,0,0,2.86,17.37l.14.14L128,205.94l-1.94,10A88.11,88.11,0,0,1,40,128Zm102.58,86.78,1.13-5.81a16.09,16.09,0,0,0-4-13.9,1.85,1.85,0,0,1-.14-.14L120,174.74,133.7,144l22.82,3.08,45.72,28.12A88.18,88.18,0,0,1,142.58,214.78Z"></path>
  </svg>
);

export const InfoIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path>
  </svg>
);

export const InfoFillIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-4,48a12,12,0,1,1-12,12A12,12,0,0,1,124,72Zm12,112a16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40a8,8,0,0,1,0,16Z"></path>
  </svg>
);

export const LightbulbIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M176,232a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,232Zm40-128a87.55,87.55,0,0,1-33.64,69.21A16.24,16.24,0,0,0,176,186v6a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16v-6a16,16,0,0,0-6.23-12.66A87.59,87.59,0,0,1,40,104.49C39.74,56.83,78.26,17.14,125.88,16A88,88,0,0,1,216,104Zm-16,0a72,72,0,0,0-73.74-72c-39,.92-70.47,33.39-70.26,72.39a71.65,71.65,0,0,0,27.64,56.3A32,32,0,0,1,96,186v6h64v-6a32.15,32.15,0,0,1,12.47-25.35A71.65,71.65,0,0,0,200,104Zm-16.11-9.34a57.6,57.6,0,0,0-46.56-46.55,8,8,0,0,0-2.66,15.78c16.57,2.79,30.63,16.85,33.44,33.45A8,8,0,0,0,176,104a9,9,0,0,0,1.35-.11A8,8,0,0,0,183.89,94.66Z"></path>
  </svg>
);

export const MagnifyingGlassIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
  </svg>
);

export const MinusIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128Z"></path>
  </svg>
);

export const MinusCircleIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M176,128a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,128Zm56,0A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
  </svg>
);

export const MinusCircleFillIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm40,112H88a8,8,0,0,1,0-16h80a8,8,0,0,1,0,16Z"></path>
  </svg>
);

export const NotebookIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M184,112a8,8,0,0,1-8,8H112a8,8,0,0,1,0-16h64A8,8,0,0,1,184,112Zm-8,24H112a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm48-88V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM48,208H72V48H48Zm160,0V48H88V208H208Z"></path>
  </svg>
);

export const PaperPlaneIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M231.87,114l-168-95.89A16,16,0,0,0,40.92,37.34L71.55,128,40.92,218.67A16,16,0,0,0,56,240a16.15,16.15,0,0,0,7.93-2.1l167.92-96.05a16,16,0,0,0,.05-27.89ZM56,224a.56.56,0,0,0,0-.12L85.74,136H144a8,8,0,0,0,0-16H85.74L56.06,32.16A.46.46,0,0,0,56,32l168,95.83Z"></path>
  </svg>
);

export const PaperPlaneFillIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M240,127.89a16,16,0,0,1-8.18,14L63.9,237.9A16.15,16.15,0,0,1,56,240a16,16,0,0,1-15-21.33l27-79.95A4,4,0,0,1,71.72,136H144a8,8,0,0,0,8-8.53,8.19,8.19,0,0,0-8.26-7.47h-72a4,4,0,0,1-3.79-2.72l-27-79.94A16,16,0,0,1,63.84,18.07l168,95.89A16,16,0,0,1,240,127.89Z"></path>
  </svg>
);

export const PaperclipIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M209.66,122.34a8,8,0,0,1,0,11.32l-82.05,82a56,56,0,0,1-79.2-79.21L147.67,35.73a40,40,0,1,1,56.61,56.55L105,193A24,24,0,1,1,71,159L154.3,74.38A8,8,0,1,1,165.7,85.6L82.39,170.31a8,8,0,1,0,11.27,11.36L192.93,81A24,24,0,1,0,159,47L59.76,147.68a40,40,0,1,0,56.53,56.62l82.06-82A8,8,0,0,1,209.66,122.34Z"></path>
  </svg>
);

export const PencilIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M227.31,73.37,182.63,28.68a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H92.69A15.86,15.86,0,0,0,104,219.31L227.31,96a16,16,0,0,0,0-22.63ZM51.31,160,136,75.31,152.69,92,68,176.68ZM48,179.31,76.69,208H48Zm48,25.38L79.31,188,164,103.31,180.69,120Zm96-96L147.31,64l24-24L216,84.68Z"></path>
  </svg>
);

export const PlusIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path>
  </svg>
);

export const PlusCircleIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path>
  </svg>
);

export const PlusCircleFillIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M128,24A104,104,0,1,0,232,128,104.13,104.13,0,0,0,128,24Zm40,112H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z"></path>
  </svg>
);

export const QuestionIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M140,180a12,12,0,1,1-12-12A12,12,0,0,1,140,180ZM128,72c-22.06,0-40,16.15-40,36v4a8,8,0,0,0,16,0v-4c0-11,10.77-20,24-20s24,9,24,20-10.77,20-24,20a8,8,0,0,0-8,8v8a8,8,0,0,0,16,0v-.72c18.24-3.35,32-17.9,32-35.28C168,88.15,150.06,72,128,72Zm104,56A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
  </svg>
);

export const QuestionFillIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,168a12,12,0,1,1,12-12A12,12,0,0,1,128,192Zm8-48.72V144a8,8,0,0,1-16,0v-8a8,8,0,0,1,8-8c13.23,0,24-9,24-20s-10.77-20-24-20-24,9-24,20v4a8,8,0,0,1-16,0v-4c0-19.85,17.94-36,40-36s40,16.15,40,36C168,125.38,154.24,139.93,136,143.28Z"></path>
  </svg>
);

export const SignOutIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M120,216a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V40a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H56V208h56A8,8,0,0,1,120,216Zm109.66-93.66-40-40a8,8,0,0,0-11.32,11.32L204.69,120H112a8,8,0,0,0,0,16h92.69l-26.35,26.34a8,8,0,0,0,11.32,11.32l40-40A8,8,0,0,0,229.66,122.34Z"></path>
  </svg>
);

export const TrashIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
  </svg>
);

export const UploadIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M224,144v64a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V144a8,8,0,0,1,16,0v56H208V144a8,8,0,0,1,16,0ZM93.66,77.66,120,51.31V144a8,8,0,0,0,16,0V51.31l26.34,26.35a8,8,0,0,0,11.32-11.32l-40-40a8,8,0,0,0-11.32,0l-40,40A8,8,0,0,0,93.66,77.66Z"></path>
  </svg>
);

export const UserIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M230.92,212c-15.23-26.33-38.7-45.21-66.09-54.16a72,72,0,1,0-73.66,0C63.78,166.78,40.31,185.66,25.08,212a8,8,0,1,0,13.85,8c18.84-32.56,52.14-52,89.07-52s70.23,19.44,89.07,52a8,8,0,1,0,13.85-8ZM72,96a56,56,0,1,1,56,56A56.06,56.06,0,0,1,72,96Z"></path>
  </svg>
);

export const UsersIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M117.25,157.92a60,60,0,1,0-66.5,0A95.83,95.83,0,0,0,3.53,195.63a8,8,0,1,0,13.4,8.74,80,80,0,0,1,134.14,0,8,8,0,0,0,13.4-8.74A95.83,95.83,0,0,0,117.25,157.92ZM40,108a44,44,0,1,1,44,44A44.05,44.05,0,0,1,40,108Zm210.14,98.7a8,8,0,0,1-11.07-2.33A79.83,79.83,0,0,0,172,168a8,8,0,0,1,0-16,44,44,0,1,0-16.34-84.87,8,8,0,1,1-5.94-14.85,60,60,0,0,1,55.53,105.64,95.83,95.83,0,0,1,47.22,37.71A8,8,0,0,1,250.14,206.7Z"></path>
  </svg>
);

export const WarningCircleIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path>
  </svg>
);

export const WarningCircleFillIcon: FC<IconProps> = ({ width, height, ...props }) => (
  <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
    <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm-8,56a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm8,104a12,12,0,1,1,12-12A12,12,0,0,1,128,184Z"></path>
  </svg>
);

// export const Icon: FC<IconProps> = ({width, height, ...props}) => (
//   <svg width={width} height={height} fill={ICON_BASIC_COLOR} viewBox="0 0 256 256" {...props}>
//
//   </svg>
// );
